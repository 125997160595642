@font-face {
  font-family: "Qatar2022-Bold";
  src: local("Qatar2022-Bold"), url(./fonts/Qatar2022-Bold.woff) format("woff");
}

@font-face {
  font-family: "Qatar2022-Thin";
  src: local("Qatar2022-Thin"), url(./fonts/Qatar2022-Thin.woff) format("woff");
}

@font-face {
  font-family: "Qatar2022-Medium";
  src: local("Qatar2022-Medium"),
    url(./fonts/Qatar2022-Medium.woff) format("woff");
}

* {
  font-family: "Qatar2022-Medium";
}
